/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper components
import "swiper/css"; // Import Swiper styles
import "swiper/css/autoplay"; // Autoplay styles (optional)
import "swiper/css/navigation"; // Navigation styles (optional)
import "swiper/css/pagination"; // Pagination styles (optional)
import { Autoplay } from "swiper/modules"; // Import Autoplay module


import Carousel from "react-multi-carousel";

const DemoSlider = () => {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
        },
    };

    const [clientlogos, setClientlogos] = useState([]);
    const [sliderReady, setSliderReady] = useState(false);

    useEffect(() => {
        axios
            .get(
                "https://megamindonline.com/admin/webmanager/controller.php?command=GET_HOME_DATA_AA"
            )
            .then((response) => {
                const logos = response.data.split(";").filter((r) => r.trim() !== "");
                // Duplicate logos if necessary for proper looping
                const extendedLogos = logos.length < 4 ? [...logos, ...logos, ...logos] : logos;
                setClientlogos(extendedLogos);
                setSliderReady(true);
            })
            .catch((error) => {
                console.error("Error fetching logos:", error);
            });
    }, []);

    return (
        <div style={{ width: "100%", margin: "0 auto" }}>
            {/* {sliderReady ? (
        <Swiper
          spaceBetween={5} // Space between slides
          slidesPerView={4} // Number of slides visible
          loop={true} // Infinite loop
          autoplay={{
            delay: 2000, // Delay between auto-slide
            disableOnInteraction: false, // Continue autoplay on interaction
          }}
          breakpoints={{
            // Responsive breakpoints
            768: {
              slidesPerView: 2, // 2 slides for medium screens
            },
            480: {
              slidesPerView: 2, // 1 slide for small screens
            },
          }}
          modules={[Autoplay]} // Include necessary Swiper modules
        >
          {clientlogos.map((logo, index) => (
            <SwiperSlide key={index}>
              <img
                src={logo}
                alt={`Logo ${index + 1}`}
                style={{
                  width: "100%",
                  height: "100px",
                  objectFit: "contain",
                  padding: "10px",
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p>Loading logos...</p>
      )} */}
            <Carousel
                responsive={responsive}
                autoPlay
                autoPlaySpeed={2000}
                additionalTransfrom={100}
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={100}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                rewind={false}
                rewindWithAnimation={true}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
                arrows={false}

            >
                {
                    clientlogos.map((logo,index ) => (
                       <img
                       src={logo}
                       style={{
                        width: "100%",
                        height: "100px",
                        objectFit: "contain",
                        padding: "10px",
                      }}
                       /> 
                    ))
                }




            </Carousel>



        </div>
    );
};

export default DemoSlider;
